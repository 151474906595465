import clsx from 'clsx';
import {Analytics} from '../KAMDashboardModel'

interface IRiskDetailsProps {
  riskDetails: Analytics[];
}

const RiskDetailsCard = ({ riskDetails }: IRiskDetailsProps) => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-4 bg-neutral-0 border border-neutral-200 rounded">
        {riskDetails?.map((details: any) => {
          return (
            <>
              <div className="border-b md:border-b-0 md:border-r  border-neutral-200 px-4 py-3 last:border-0 ">
                <div className="flex justify-between">
                  <span className="text-neutral-500 text-base font-medium">
                    {details.title}
                  </span>
                  <span
                    className={clsx('font-medium text-base', {
                      'text-error-700': details.trend === 'down',
                      'text-secondary-700': details.trend === 'up',
                    })}
                  >
                  { details.trend === 'up' ? '+':''}{details.deviation}
                  </span>
                </div>
                <div className="mt-1 font-semibold text-lg">
                  {details.value}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default RiskDetailsCard;
