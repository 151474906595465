import { ThemeCard, Tile } from '@kamet/common';
import moment from 'moment';
import clsx from 'clsx';
import { ErrorIcon, Success, Warning } from '@kamet/common/src/assets/icons';
import { DailyRisks } from '../KAMDashboardModel';
import RiskDetailsModal from './RiskDetailsModal';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { convertUtcToSelectedTimezone } from '../../../common/utils/imageUpload.utils';
interface IDownTimeCardProps {
  pageContent: any;
  downTimeDetails: any;
  isProviderIntegrated: Boolean;
}
const DowntimeCard = (props: IDownTimeCardProps) => {
  const cardStaticData = props.pageContent;
  // const downTimeDetails =
  const [isRiskModalopen, setIsRiskModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const userDetails = useSelector(
    (state: RootState) => state.userDetails.userDetails
  );
  const localTimezone = userDetails?.timezone
    ? userDetails?.timezone
    : 'US/Pacific';
  const getVariant: any = (details: any) => {
    if (props.isProviderIntegrated === true) {
      if (details.highRisk > 0) {
        return 'danger'; // High risk
      } else if (details.mediumRisk > 0) {
        return 'warning'; // Medium risk
      } else if (details.lowRisk > 0) {
        return 'success'; // Low risk
      } else {
        return 'success'; // for no risk
      }
    }
    return 'defaultTooltip'; // Default
  };

  const showRiskDetailsModal = (details: any, events: any, date: string) => {
    if (details?.highRisk != 0 || details?.mediumRisk != 0) {
      if (events > 0) {
        setSelectedEvent(date);
        setIsRiskModalOpen(true);
      } else {
      }
    }
  };
  // for converting utc time based on the selected timezonee
  // const downTimeDetails =  props.downTimeDetails.map((item: any) => {
  //   const [date, time] = item.date.split(' ');
  //   return { ...item, date: convertUtcToSelectedTimezone(date, time, localTimezone)}
  // });
  const downTimeDetails = props?.downTimeDetails;

  const onClickModalClose = () => {
    setIsRiskModalOpen(false);
  };
  const formatTime = (time: any) => {
    const timeInHours = Math.floor(time / 3600);
    const days = Math.floor(timeInHours / 24);
    const hours = timeInHours % 24;
    const remainingSecondsAfterHours = time % 3600; // Remaining seconds after full hours
    const minutes = Math.floor(remainingSecondsAfterHours / 60); // Convert remaining seconds to minutes
    const seconds = Math.floor(remainingSecondsAfterHours % 60); //
    let dayUnit = 'day';
    let hourUnit = 'hr';
    if (days > 1) {
      dayUnit = 'days';
    }
    if (hours > 1) {
      hourUnit = 'hrs';
    }
    let timeFromat;
    if (timeInHours >= 24) {
      timeFromat = `${days}${dayUnit} ${hours}${hourUnit}`;
    } else {
      timeFromat = `${hours}${hourUnit} ${minutes}min`;
    }

    return timeFromat;
  };
  const formatCost = (cost: any) => {
    const costWithoutDecimalPoint = Math.trunc(cost);
    return new Intl.NumberFormat().format(costWithoutDecimalPoint);
  };
  return (
    <>
      <ThemeCard>
        <div className="flex justify-between font-medium text-neutral-950 text-base">
          <span>{cardStaticData.downTimeTitle}</span>
          <span>{`Last ${downTimeDetails && downTimeDetails?.length ? downTimeDetails?.length : 0} days`}</span>{' '}
        </div>
        <section className="mt-2 ">
          <div className="flex gap-1">
            {downTimeDetails &&
              downTimeDetails?.map((details: any) => {
                const breakDownEvents = [
                  { event: 'Time', value: `${formatTime(details?.time)}` },
                  { event: 'Cost', value: `$${formatCost(details?.cost)}` },
                ];
                const utcDate = details.date; // Replace with your UTC date
                const date = moment(utcDate); // 12:00 AM on 10/09/24
                const formattedDate = date.format('ddd, MMM DD');
                const length = details?.count;

                return (
                  <Tile
                    variant={getVariant(details)}
                    date={formattedDate}
                    // onclick={() =>
                    //   showRiskDetailsModal(details, details.count, details.date)
                    // }
                  >
                    <span className="flex justify-centertext-sm font-medium text-neutral-950 ">
                      {moment(details.date).format('dddd, MMM DD')}
                    </span>
                    <div
                      // className={clsx('py-1 flex items-center gap-1', {
                      //   'text-secondary-500':
                      //     details.lowRisk >= 0 &&
                      //     details.highRisk === 0 &&
                      //     details.mediumRisk === 0, // No high or medium risk, only low risk
                      //   'text-warning-500 border-b border-neutral-200':
                      //     details.mediumRisk > 0 && details.highRisk === 0, // No high risk, but medium risk exists
                      //   'text-error-500 border-b border-neutral-200':
                      //     details.highRisk > 0, // High risk exists
                      // })}
                      className="py-1 flex items-center gap-1 text-neutral-700"
                    >
                      {details.highRisk > 0 ? (
                        <>
                          {/* <ErrorIcon /> */}
                          <Warning />
                          <span>
                            {length}{' '}
                            {length > 1
                              ? cardStaticData.eventsDetected
                              : cardStaticData.eventDetected}
                          </span>
                        </>
                      ) : details.mediumRisk > 0 ? (
                        <>
                          <Warning />
                          <span>
                            {length}{' '}
                            {length > 1
                              ? cardStaticData.eventsDetected
                              : cardStaticData.eventDetected}
                          </span>
                        </>
                      ) : details.lowRisk > 0 ? (
                        <span>{cardStaticData.noMajorEvent}</span>
                      ) : (
                        <span>{cardStaticData.noMajorEvent}</span>
                      )}
                    </div>
                    {details.highRisk > 0 || details.mediumRisk > 0 ? (
                      <>
                        {breakDownEvents?.map((data) => {
                          return (
                            <div className="flex justify-between mt-1 gap-2">
                              <span className="text-sm text-neutral-500 font-medium">
                                {data.event}
                              </span>
                              <div className="text-sm font-medium text-neutral-950">
                                {data.value}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      ''
                    )}
                  </Tile>
                );
              })}
          </div>
        </section>
      </ThemeCard>

      <RiskDetailsModal
        isOpen={isRiskModalopen}
        onClose={onClickModalClose}
        data={selectedEvent}
      />
    </>
  );
};

export default DowntimeCard;
