import moment from 'moment-timezone';
const isValidImage = (e: any) => {
  const file = e.target.files ? e.target.files[0] : null;
  const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  if (file && validImageTypes.includes(file.type)) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return {
      isValid: true,
      imagePath: URL.createObjectURL(file), // Use this line for image path
    };
  } else {
    return {
      isValid: false,
    };
  }
};

export { isValidImage };
export const convertDate = (dateString: string): string => {
  // Create a Date object from the ISO date string (YYYY-MM-DD)
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }

  // Format the date as MM/DD/YY
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };
  return date.toLocaleDateString('en-US', options);
};

export const convertTime = (
  utcTimeString: string,
  timezone: string
): string => {
  const [hours, minutes, seconds] = utcTimeString.split(':').map(Number);
  const utcDate = moment
    .utc()
    .set({ hour: hours, minute: minutes, second: seconds });
  if (!utcDate.isValid()) {
    throw new Error('Invalid time string');
  }

  const convertedTime = timezone
    ? utcDate.tz(timezone).format('hh:mm A')
    : utcDate.local().format('hh:mm A');
  return convertedTime;
};

// Converts a date and time to the specified timezone
export const convertDateTime = (
  dateString: string,
  timeString: string,
  timezone: string
): string => {
  // Create a Date object from the ISO date string (YYYY-MM-DD)
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }

  // Combine date and time into a single moment object in UTC
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  const utcDateTime = moment
    .utc(date)
    .set({ hour: hours, minute: minutes, second: seconds });

  if (!utcDateTime.isValid()) {
    throw new Error('Invalid time string');
  }

  // Convert the datetime to the specified timezone
  const convertedDateTime = timezone
    ? utcDateTime.tz(timezone).format('YYYY-MM-DD HH:mm:ss') // Customize format as needed
    : utcDateTime.local().format('YYYY-MM-DD HH:mm:ss');

  return convertedDateTime;
};

export const convertUtcToSelectedTimezone = (
  dateString: string, // Date string in UTC (e.g., '2024-09-06')
  timeString: string, // Time string in UTC (e.g., '03:40:00')
  selectedTimezone: string // Timezone selected from dropdown (e.g., 'America/Toronto')
): string => {
  const utcDateTime = moment.utc(
    `${dateString} ${timeString}`,
    'YYYY-MM-DD HH:mm:ss'
  );

  if (!utcDateTime.isValid()) {
    throw new Error('Invalid date or time string');
  }

  // Convert to the selected timezone
  const convertedDateTime = selectedTimezone
    ? utcDateTime.tz(selectedTimezone).format('h:mm A  MM/DD/YY')
    : utcDateTime.local().format('h:mm A  MM/DD/YY');

  return convertedDateTime;
};
