import { MouseEventHandler } from 'react';
import IntegrationEnableComponent from './components/EnableIntegration.component';
import DowntimeCard from './components/DowntimeCard.component';
import Content from '../../constants/ContentFile.json';
import RiskDetailsCard from './components/RiskDetailsCard.Component';
import RiskChartCard from './components/RiskChartCard.component';
import {
  Analytics,
  DailyRisks,
  OverallRisk,
  Risk,
  Risks,
} from './KAMDashboardModel';
import RiskDetailsModal from './components/RiskDetailsModal';
import OperationsRiskCard from './components/OperationsRiskCard.component';
import MachineOperationsCard from './components/MachineOperationsCard';
import MachinePerformanceCard from './components/MachinePerformanceCard';

const staticContent = Content.dashboardPage;

interface IDashboardProps {
  onclikcEnableButton: MouseEventHandler<HTMLButtonElement>;
  isEnabled: Boolean;
  downTimeDetails: any;
  performaceDetails: any;
  riskDetails: Analytics[];
  operationalDetails:any;
}

const DashboardView = (props: IDashboardProps) => {
  const downTimeCardData = staticContent.downTimeCardDetails;
  return (
    <>
      <h1 className="text-2xl font-semibold text-neutral-950">
        {staticContent.pageTitle}
      </h1>
      {!props.isEnabled ? (
        <section className="mt-4">
          <IntegrationEnableComponent
            onClick={props.onclikcEnableButton}
            pageContent={staticContent.enableIntegrationCradDetails}
          />
        </section>
      ) : null}

      <section className="mt-4">
        <DowntimeCard
          pageContent={downTimeCardData}
          downTimeDetails={props.downTimeDetails}
          isProviderIntegrated={props.isEnabled}
        />
      </section>
      <section className="mt-4">
      <RiskDetailsCard riskDetails={props?.riskDetails} />
      </section>
      <section>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
          <OperationsRiskCard operationsDetails={props?.operationalDetails} />
          <RiskChartCard performaceRiskDetails={props?.performaceDetails} />
          {/* TODO :change the operationsDetails with machine related details (api change)*/}
          <MachineOperationsCard operationsDetails={props?.operationalDetails} /> 
          <MachinePerformanceCard performaceDetails={props?.performaceDetails} /> 
        </div>
      </section>
    </>
  );
};

export default DashboardView;
