import { get } from '../../common/service/apiClient';
import axios from 'axios';
import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_EMPTY,
  GET_OPERATION_DETAILS,
  GET_OPERATIONS_MACHINES,
  GET_PERFORMANCE_DETAILS,
  GET_PERFORMANCE_MACHINES,
} from '../../common/service/serviceEndPoints';
import CustomError from '../../common/service/CustomError';

export const getDashboardData = async (): Promise<any> => {
  try {
    const response = await get<any>(GET_DASHBOARD_DATA);
    return response.data;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(
        'An unknown error occurred while fetching dashboard data',
        500,
        ''
      );
    }
  }
};

export const getRiskDetailsData = async (date: string): Promise<any> => {
  try {
    const response = await get<any>(`/events/details/?date=${date}`);
    return response.data;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(
        'An unknown error occurred while fetching dashboard data',
        500,
        ''
      );
    }
  }
};

export const WeeklyPerformanceDetails = async (value: number): Promise<any> => {
  try {
    const response = await get<any>(`${GET_PERFORMANCE_DETAILS}?risk=${value}`);
    return response.data;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(
        'An unknown error occurred while fetching dashboard data',
        500,
        ''
      );
    }
  }
};

export const WeeklyOperationDetails = async (value: number): Promise<any> => {
  try {
    const response = await get<any>(`${GET_OPERATION_DETAILS}?risk=${value}`);
    return response.data;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(
        'An unknown error occurred while fetching dashboard data',
        500,
        ''
      );
    }
  }
};
export const fetchMachinesUnderPerformance = async (value: number): Promise<any> => {
  try {
    const response = await get<any>(`${GET_PERFORMANCE_MACHINES}?risk=${value}`);
    return response.data;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(
        'An unknown error occurred while fetching dashboard data',
        500,
        ''
      );
    }
  }
};
export const fetchMachinesUnderOperations = async (value: number): Promise<any> => {
  try {
    const response = await get<any>(`${GET_OPERATIONS_MACHINES}?risk=${value}`);
    return response.data;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(
        'An unknown error occurred while fetching dashboard data',
        500,
        ''
      );
    }
  }
};
