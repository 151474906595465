//List all api endpoints
export const GET_PROVIDERS = 'provider/';
//export const GET_DASHBOARD_DATA = 'get_dashboard_data/';
export const GET_DASHBOARD_MOCK_DATA = '/analytics/all/';
export const GET_DASHBOARD_DATA = '/event/dashboard/';
export const GET_DASHBOARD_EMPTY = '/dashboard_empty';
export const GET_USERS = 'auth/users/';
export const GET_ROLES_LIST = 'roles/';
export const UPDATE_ROLE = 'auth/users/';
export const GET_USER_INFO = 'auth/users/me/';
export const GET_ORGANIZATION_DATA = 'organizations/me/';
export const SET_ORGANIZATION_DATA = 'organizations';
export const GET_ROLES_N_PERMISSIONS = 'auth/users/permissions/';
export const GET_PERFORMANCE_DETAILS = 'events/performance/';
export const GET_OPERATION_DETAILS = 'events/operations/';
export const EVENT_TRIGGER = 'event/trigger/';
export const GET_PERFORMANCE_MACHINES = 'events/machine/performance/';
export const GET_OPERATIONS_MACHINES = 'events/machine/operations/';
