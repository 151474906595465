import { useEffect, useState } from 'react';
import IntegrationsView from './ProviderIntegrations.view';
import StaticConents from '../../constants/ContentFile.json';
import RouteConstants from '../../constants/routeConstants.json';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProviders,
  selectProviders,
  selectProvidersLoading,
  selectProvidersError,
  updateProvider,
  setInputError,
  setInputs,
  clearInputError,
  setInputErrors,
  integrateProvider,
  disableProviders,
  disableShowModal,
} from './providerSlice';
import { RootState, AppDispatch } from '../../redux/store';
import { isValidFields } from './ProviderIntegrations.utils';
import { reset } from './providerSlice';
import { Loader } from '@kamet/common';
import ErrorPage from '../../common/component/ErrorPage/Errorpage';
import { useNavigate } from 'react-router-dom';
import { fetchUserInfo } from '../SplashScreen/SplashSlice';
type alertType = 'success' | 'error' | 'information' | 'warning';
const IntegrationsPage = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const [selectedProviderName, setSelectedProviderName] = useState('');
  const [showAlertToast, setShowAlertToast] = useState(false);
  const [alertToastType, setAlertToastType] = useState<alertType>('success');
  const [toastMessage, setToastMessage] = useState('');
  const [selectedUrl, setSelectedUrl] = useState('');
  // const [autoSimulateData, setAutoSimulateData] = useState({});
  const dispatch = useDispatch() as AppDispatch;
  const providers = useSelector((state: RootState) => selectProviders(state));
  const loading = useSelector((state: RootState) =>
    selectProvidersLoading(state)
  );

  const inputErrors = useSelector(
    (state: RootState) => state.providers.inputErrors
  );
  const inputs = useSelector((state: RootState) => state.providers.inputs);
  const error = useSelector((state: RootState) => selectProvidersError(state));
  const showModalAfterCred = useSelector(
    (state: RootState) => state.providers.showModal
  );
  const userDetails = useSelector(
    (state: RootState) => state.userDetails.userDetails
  );
  const timezone = userDetails?.timezone ? userDetails?.timezone : 'US/Pacific';

  // viam static data to auto simulate
  const viamStaticData = {
    events: [
      {
        risk: {
          domain: '',
          event: 'Speed',
          value: '12',
          provider: null,
          date:  moment
          .tz(moment().subtract(5, 'days'), timezone)
          .format('YYYY-MM-DD'),
        },
        machine_details: [
          {
            name: 'Machine9',
            location: 'Zone D',
            start_time: moment.tz('05:55', 'HH:mm', timezone).format('HH:mm'), // Convert the start_time to Pacific timezone
            start_date: moment
              .tz(moment().subtract(5, 'days'), timezone)
              .format('YYYY-MM-DD'),
            provider: null,
            value: '12',
          },
        ],
      },
      {
        risk: {
          domain: '',
          event: 'Camera Sensor',
          value: '0',
          provider: null,
          date: moment
          .tz(moment().subtract(6, 'days'), timezone)
          .format('YYYY-MM-DD'),
        },
        machine_details: [
          {
            name: 'Machine8',
            location: 'Zone A',
            start_time: moment.tz('04:00', 'HH:mm', timezone).format('HH:mm'), // Convert the start_time to Pacific timezone
            start_date: moment
              .tz(moment().subtract(6, 'days'), timezone)
              .format('YYYY-MM-DD'),
            provider: null,
            value: '0',
          },
        ],
      },
    ],
  };

  // ion static data to auto simulate
  // ion static data
  const ionStaticData = {
    events: [
      {
        risk: {
          domain: '',
          event: 'Authorization',
          value: '3',
          provider: null,
          date: moment
          .tz(moment().subtract(6, 'days'), timezone)
          .format('YYYY-MM-DD'),
        },
        machine_details: [
          {
            name: 'Machine8',
            location: 'Zone D',
            start_time: moment.tz('07:00', 'HH:mm', timezone).format('HH:mm'), // Convert the start_time to Pacific timezone
            start_date: moment
              .tz(moment().subtract(6, 'days'), timezone)
              .format('YYYY-MM-DD'),
            provider: null,
            value: '3',
          },
        ],
      },
      {
        risk: {
          domain: '',
          event: 'Configuration',
          value: '3',
          provider: null,
          date: moment
          .tz(moment().subtract(6, 'days'), timezone)
          .format('YYYY-MM-DD'),
        },
        machine_details: [
          {
            name: 'Machine7',
            location: 'Zone E',
            start_time: moment.tz('07:00', 'HH:mm', timezone).format('HH:mm'), // Convert the start_time to Pacific timezone
            start_date: moment
              .tz(moment().subtract(6, 'days'), timezone)
              .format('YYYY-MM-DD'),
            provider: null,
            value: '3',
          },
        ],
      },
      {
        risk: {
          domain: '',
          event: 'Calibration',
          value: '2',
          provider: null,
          date: moment
          .tz(moment().subtract(5, 'days'), timezone)
          .format('YYYY-MM-DD'),
        },
        machine_details: [
          {
            name: 'Machine9',
            location: 'Zone H',
            start_time: moment.tz('08:00', 'HH:mm', timezone).format('HH:mm'), // Convert the start_time to Pacific timezone
            start_date: moment
              .tz(moment().subtract(5, 'days'), timezone)
              .format('YYYY-MM-DD'),
            provider: null,
            value: '2',
          },
        ],
      },
    ],
  };

  useEffect(() => {
    dispatch(fetchProviders());
    dispatch(fetchUserInfo());
  }, [dispatch]);
  //  show modal only after credentials api success
  useEffect(() => {
    if (showModalAfterCred) {
      setShowModal(true);
      setShowRemoveModal(false);
    }
  }, [showModalAfterCred]);
  // changing toggle button
  const onChangeToggle = (id: any) => {
    setSelectedProviderId(id);
    providers?.filter((item) => {
      if (item.id === id) {
        setSelectedProviderName(item?.name);
        setSelectedUrl(item.url);
        if (item.isEnabled === true) {
          setShowRemoveModal(true);
          setShowModal(false);
        } else {
          // for showing dynamic modal for authentication :options api
          dispatch(updateProvider(item.url));
        }
      }
    });
    setShowAlertToast(false);
  };

  // on changing inputs
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    maxLength: number
  ) => {
    const { name, value } = e.target;
    // Validate input
    const { error, isValid } = isValidFields(value, name, maxLength);
    // Dispatch error if validation fails
    if (!isValid) {
      dispatch(setInputError({ name, error }));
    } else {
      dispatch(clearInputError(name));
    }
    // Dispatch the updated inputs array
    const updatedInputs = inputs.map((input: any) =>
      input.name === name ? { ...input, value: value } : input
    );
    dispatch(setInputs(updatedInputs));
  };
  // Integration Modal Close click
  const integrationModalClose = () => {
    dispatch(reset());
    setShowModal(false);
  };

  // data to be sent for integrate provider api
  const prepareDataForApi = (inputs: any[]) => {
    const data: Record<string, string> = {};
    inputs.forEach((input) => {
      const { name, value } = input;
      if (name) {
        data[name] = value;
      }
    });
    return data;
  };
  // on submiting  modal
  const onModalSubmit = () => {
    let hasErrors = false;
    const updatedErrors: Record<string, string> = {};
    // Validate each input
    inputs?.forEach((input) => {
      const { name, value, maxLength } = input;
      const { error, isValid } = isValidFields(value, name, maxLength);
      if (!isValid) {
        hasErrors = true;
        updatedErrors[name] = error;
      }
    });
    // Dispatch errors if any
    if (hasErrors) {
      dispatch(setInputErrors(updatedErrors));
    } else {
      let autoSimulateData;
      if (selectedProviderName.toLocaleLowerCase() === 'viam') {
        // for adding selected provider id
        const providerUpdatedData = {
          ...viamStaticData, // Copy the original structure
          events: viamStaticData.events.map((event) => ({
            ...event,
            risk: {
              ...event.risk,
              provider: Number(selectedProviderId), // Update provider in risk
            },
            machine_details: event.machine_details.map((machine) => ({
              ...machine,
              provider: Number(selectedProviderId), // Update provider in machine details
            })),
          })),
        };

        autoSimulateData = providerUpdatedData;
      } else if (
        selectedProviderName.toLocaleLowerCase() === 'first resonance'
      ) {
        // for adding selected provider id
        const providerUpdatedData = {
          ...ionStaticData, // Copy the original structure
          events: ionStaticData.events.map((event) => ({
            ...event,
            risk: {
              ...event.risk,
              provider: Number(selectedProviderId), // Update provider in risk
            },
            machine_details: event.machine_details.map((machine) => ({
              ...machine,
              provider: Number(selectedProviderId), // Update provider in machine details
            })),
          })),
        };
        autoSimulateData = providerUpdatedData;
      }
      const data = prepareDataForApi(inputs);
      const updatedUrl = selectedUrl.endsWith('/')
        ? selectedUrl
        : `${selectedUrl}/`;
      setShowModal(false);
      dispatch(disableShowModal());
      dispatch(
        integrateProvider(
          updatedUrl,
          data,
          setShowAlertToast,
          setAlertToastType,
          setToastMessage,
          autoSimulateData
        )
      );
    }
  };

  // on remove modal close
  const onRemoveModalClose = () => {
    setShowRemoveModal(false);
  };

  // on remove modal submit
  const onRemoveButtonSubmit = () => {
    setShowRemoveModal(false);
    dispatch(disableProviders(selectedUrl));
    setShowAlertToast(true);
    setAlertToastType('success');
    setToastMessage(StaticConents.providerIntegration.integrationDisable);
  };

  // cancel toast
  const onClickCancelToast = () => {
    setShowAlertToast(false);
    dispatch(disableShowModal());
  };
  // error page action button
  const ErrorActionClick = () => {
    window.location.reload();
  };
  if (loading === 'pending') return <Loader />;

  if (error && !error.isToast)
    return (
      <ErrorPage
        statusCode={error.statusCode ? `${error.statusCode}` : ''}
        description={error.message}
        buttonText="Navigate to Dashboard"
        isButtonVisible={true}
        action={ErrorActionClick}
      />
    );

  return (
    <IntegrationsView
      onChange={onChangeToggle}
      data={providers ? providers : []}
      isModalopen={showModal}
      onModalClose={integrationModalClose}
      onInputChange={handleInputChange}
      inputErrors={inputErrors}
      onModalSubmit={onModalSubmit}
      isRemoveModalOpen={showRemoveModal}
      removeButtonClick={onRemoveButtonSubmit}
      onRemoveModalClose={onRemoveModalClose}
      showAlertToast={showAlertToast}
      alertToastMessage={toastMessage}
      toastType={alertToastType}
      selectedProviderName={selectedProviderName}
      cancelToast={onClickCancelToast}
      inputs={inputs ? inputs : inputs}
    />
  );
};

export default IntegrationsPage;
