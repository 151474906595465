import {
  Accordion,
  AlertModal,
  BarChart,
  DoughnutChart,
  ThemeCard,
} from '@kamet/common';
import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  fetchMachinesList,
  fetchOperationsMachinesList,
  getMachineList,
  machineNameDetails,
} from '../DashboardSlice';

interface IOperationsChartProps {
  operationsDetails: any;
}
const MachineOperationsCard = (props: IOperationsChartProps) => {
  const operationsDetails = props.operationsDetails;
  const [riskType, setRiskType] = useState<any>('');
  const dispatch = useDispatch() as AppDispatch;
  const machineNameList = useSelector((state: RootState) =>
    machineNameDetails(state)
  );
  const [modal, setModal] = useState(false);
  const chartData = [
    operationsDetails?.lowRiskPercentage
      ? operationsDetails?.lowRiskPercentage
      : 0,

    operationsDetails?.mediumRiskPercentage
      ? operationsDetails?.mediumRiskPercentage
      : 0,

    operationsDetails?.highRiskPercentage
      ? operationsDetails?.highRiskPercentage
      : 0,
  ];
  const percentageArray = [
    operationsDetails?.lowRiskPercentage
      ? operationsDetails?.lowRiskPercentage
      : 0,
    operationsDetails?.mediumRiskPercentage
      ? operationsDetails?.mediumRiskPercentage
      : 0,
    operationsDetails?.highRiskPercentage
      ? operationsDetails?.highRiskPercentage
      : 0,
  ];

  const sumValue =
    percentageArray &&
    percentageArray.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue,
      0
    );
  const coloredPercentageArray = [
    {
      percentage: operationsDetails?.lowRiskPercentage
        ? operationsDetails?.lowRiskPercentage
        : 0,
      color: '#10B981',
    }, // Low risk is green
    {
      percentage: operationsDetails?.mediumRiskPercentage
        ? operationsDetails?.mediumRiskPercentage
        : 0,
      color: '#F59E0B',
    }, // Medium risk is orange
    {
      percentage: operationsDetails?.highRiskPercentage
        ? operationsDetails?.highRiskPercentage
        : 0,
      color: '#EF4444',
    }, // High risk is red
  ];
  const bgColors = coloredPercentageArray.map((item) =>
    item.percentage > 0 ? item.color : ''
  );
  const hundredPercentage = percentageArray?.includes(100);
  // for showing chart labels

  const chartLabels = ['Low Risk', 'Medium Risk', 'High Risk'];
  // number of machines
  const machineCount = [
    operationsDetails?.lowMachineCount ? operationsDetails?.lowMachineCount : 0,
    operationsDetails?.mediumMachineCount
      ? operationsDetails?.mediumMachineCount
      : 0,
    operationsDetails?.highMachineCount
      ? operationsDetails?.highMachineCount
      : 0,
  ];
  // Creating an array of risk objects
  const risks = [
    {
      title: 'No Risk',
      percentage: operationsDetails?.lowRiskPercentage
        ? operationsDetails?.lowRiskPercentage
        : 0,
      status: 'No',
    },
    {
      title: 'Medium Risk',
      percentage: operationsDetails?.mediumRiskPercentage
        ? operationsDetails?.mediumRiskPercentage
        : 0,
      status: 'Medium',
    },
    {
      title: 'High Risk',
      percentage: operationsDetails?.highRiskPercentage
        ? operationsDetails?.highRiskPercentage
        : 0,
      status: 'High',
    },
  ];
  // Check if all percentages are 0
  const allRisksZero = risks?.every((risk) => risk?.percentage === 0);

  // clicking on bar chart
  const handleSegmentClick = (index: any) => {
    dispatch(getMachineList(''));
    setRiskType(
      index === 2 ? 'high_risk' : index === 1 ? 'medium_risk' : 'low_risk'
    );
    const riskValue = index === 2 ? 3 : index === 1 ? 2 : 1; //3-high 2-medium 1-low
    dispatch(fetchOperationsMachinesList(riskValue)); //api call for machine list
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    setRiskType('');
  };
  return (
    <>
      <ThemeCard>
        <h3 className="font-medium text-base text-neutral-950 text-center">
          {'Machine Operations'}
        </h3>
        <div className="flex justify-center">
          <div className="my-4 w-40 ">
            <BarChart
              variant={
                percentageArray &&
                percentageArray.length > 0 &&
                sumValue &&
                sumValue > 0
                  ? 'primary'
                  : 'default'
              }
              backgroundColor={bgColors ? bgColors : ['#777']}
              chartData={chartData}
              cutout={35}
              borderWidth={hundredPercentage ? 1 : 1}
              borderRadius={hundredPercentage ? 4 : 4}
              chartLabels={chartLabels} // Pass titles to the chart
              showModal={false}
              showTooltip={true}
              machineCount={machineCount}
              onClickSegment={handleSegmentClick}
            />
          </div>
        </div>

        <section>
          {risks.map((data) => {
            return (
              <div
                key={data.title}
                className="border-b border-neutral-200 flex justify-between pb-1 mb-2 last:border-0"
              >
                <div className="flex">
                  <div
                    className={clsx('w-1 h-6 rounded-md', {
                      'bg-neutral-200': allRisksZero, // Neutral color if all risks are 0
                      'bg-secondary-500':
                        !allRisksZero && data.title === 'No Risk', // Green for No Risk
                      'bg-warning-500':
                        !allRisksZero && data.title === 'Medium Risk', // Orange for Medium Risk
                      'bg-error-500':
                        !allRisksZero && data.title === 'High Risk', // Red for High Risk
                    })}
                  ></div>
                  <span className="ml-2 text-base text-neutral-500 font-medium">
                    {data.title}
                  </span>
                </div>
                <span className="text-base text-neutral-950 font-medium">
                  {data.percentage}%
                </span>
              </div>
            );
          })}
        </section>
      </ThemeCard>
      <AlertModal
        isOpen={modal}
        onClose={closeModal}
        title={`Machines under ${
          riskType === 'high_risk'
            ? 'High'
            : riskType === 'medium_risk'
              ? 'Medium'
              : 'No'
        }  Risk`}
        closeButtonText="Close"
        cancelButtonStyle="hidden"
      >
        <div className="text-neutral-600 text-base border-t-[1px] pt-2">
          {machineNameList &&
            machineNameList.machines_names.map((item: any) => item).join(', ')}
        </div>
      </AlertModal>
    </>
  );
};

export default MachineOperationsCard;
