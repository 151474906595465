import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';
import { cn } from '../../utils';
import { Cancel, DropDownIcon, ErrorIcon } from '../../assets/icons';
import '../../index.css';
import clsx from 'clsx';
interface DropDownProps extends VariantProps<typeof dropdownVariants> {
  name?: string;
  placeholder?: string;
  errors?: boolean;
  value?: any;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  errorMessage?: string;
  helpText?: string;
  label?: string;
  options: { value: any; label: string }[];
  disabled?: boolean;
  clearselectedValue?: boolean;
  onclickRemoveValue?: () => void;
}

const dropdownVariants = cva(
  'px-4 py-2 w-full h-10 text-base rounded border outline-none font-medium font-custom',
  {
    variants: {
      variant: {
        primary:
          'border-neutral-200 focus-visible:border-primary-600 focus:ring focus:ring-primary-200',
        danger:
          'border-error-700 focus-visible:border-error-700 focus:ring focus:ring-error-200',
        disable: `opacity-50 bg-neutral-100 cursor-not-allowed text-neutral-700 pointer-events-none`,
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
);

const DropDown = ({
  variant,
  name,
  placeholder,
  errors,
  onChange,
  value,
  errorMessage,
  helpText,
  label,
  options,
  disabled,
  clearselectedValue,
  onclickRemoveValue,
}: DropDownProps) => {
  return (
    <div className="flex flex-col gap-2 relative">
      {label && (
        <label className="flex float-left text-neutral-950 text-base font-medium">
          {label}
        </label>
      )}
      <div className="relative">
        <select
          value={value}
          onChange={onChange}
          className={clsx(
            cn(dropdownVariants({ variant }), 'custom-select truncate'),
            {
              'text-neutral-400': value === '',
              'text-neutral-950': value !== '',
              'pr-12': clearselectedValue === true,
              'pr-7': clearselectedValue === false,
            }
          )}
          name={name}
          disabled={disabled}
        >
          <option value="" disabled={true} selected>
            {placeholder}
          </option>
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              className="text-neutral-950 "
            >
              {option.label}
            </option>
          ))}
        </select>
        {clearselectedValue ? (
          <div className="cursor-pointer absolute inset-y-0 right-10 flex items-center">
            <Cancel
              className="text-neutral-400 text-xs"
              onClick={onclickRemoveValue}
            />
          </div>
        ) : (
          ''
        )}
        <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none">
          {disabled === true ? (
            <DropDownIcon className="text-neutral-300" />
          ) : (
            <DropDownIcon className="text-neutral-400" />
          )}
        </div>
      </div>
      {helpText && (
        <div className="flex min-h-5">
          <p className="text-base font-medium text-neutral-500">{helpText}</p>
        </div>
      )}
      {errors && (
        <div className="flex items-center gap-2">
          <ErrorIcon className="text-xl text-error-700" />
          <p className=" text-base font-medium text-error-700">
            {errorMessage}
          </p>
        </div>
      )}
    </div>
  );
};

export default DropDown;
