// BarChart.tsx
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';

interface IBarChartProps {
  backgroundColor?: string[];
  chartData?: number[];
  variant: string;
  cutout?: number;
  borderRadius?: number;
  borderWidth?: number;
  chartLabels?: any;
  // modal props
  modalTitle?: string;
  children?: any;
  onClose?: () => void;
  closeButtonText?: string;
  showModal?: boolean;
  onClickSegment?: any;
  showTooltip?: boolean;
  machineCount?: any;
}

// Register the necessary chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({
  variant,
  backgroundColor,
  chartData,
  cutout,
  borderRadius,
  borderWidth,
  chartLabels,
  showModal,
  onClickSegment,
  showTooltip,
  machineCount,
}: IBarChartProps) => {
  // Define the data structure (with typing)
  // Data for the chart - styles

  const defaultChart: ChartData<'bar'> = {
    labels: chartLabels || [],
    datasets: [
      {
        data: [100, 100, 100],
        backgroundColor: '#E4E4E7',
        borderColor: 'transparent' || ['#777'],
        borderRadius: borderRadius || 0,
        barThickness: 40,
        barPercentage: 1, // Removes space between bars
        categoryPercentage: 0.5,
      },
    ],
  };

  const primaryChart: ChartData<'bar'> = {
    labels: chartLabels || [],
    datasets: [
      {
        data: chartData || [],
        backgroundColor: backgroundColor || ['#777'],
        borderColor: 'transparent' || ['#777'],
        // borderWidth: borderWidth || 1,
        borderRadius: borderRadius || 0,
        barThickness: 40,
        barPercentage: 1, // Removes space between bars
        categoryPercentage: 0.5,
      },
    ],
  };
  const handleClick = (event: any, elements: any) => {
    if (variant === 'primary') {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        onClickSegment && onClickSegment(elementIndex); // Call the onClickSegment prop with the index of the clicked bar
      }
    }
  };
  // Define chart options (with typing)
  const options: ChartOptions<'bar'> = {
    responsive: true,
    onClick: handleClick, // Attach the handleClick function here
    maintainAspectRatio: false, // Allow chart to fill the container
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: showTooltip ? true : false,
        displayColors: false,
        backgroundColor: '#ffffff', // Tooltip background color
        titleFont: {
          size: 14,
          weight: 'bold',
          family: 'Arial',
        },
        bodyFont: {
          size: 14,
          family: 'Arial',
        },
        bodyColor: '#000000',
        borderColor: '#f4f4f5',
        borderWidth: 2,
        padding: 10,
        callbacks: {
          title: function () {
            return ''; // Return empty string to hide the title
          },
          label: function (tooltipItem: any) {
            const label = machineCount
              ? machineCount[tooltipItem.dataIndex]
              : '';
            const value = tooltipItem.raw;
            const eventLabel = Number(label) > 1 ? 'Machines' : 'Machine';
            return `${label} ${eventLabel}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
        min: 0,
        max: 100,
      },
    },
  };
  return (
    <>
      {variant === 'default' && (
        <div className=" h-full">
          {/* Make the container flexible */}
          <Bar data={defaultChart} options={options} />
        </div>
      )}
      {variant === 'primary' && (
        <div className=" h-full">
          {' '}
          {/* Adjust height and width dynamically */}
          <Bar data={primaryChart} options={options} />
        </div>
      )}
    </>
  );
};

export default BarChart;
