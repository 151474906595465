import { Button, DropDown, Input, Toast, ToggleSwitch } from '@kamet/common';
import Card from '@kamet/common/src/components/Themecard/ThemeCard';
import constants from '../../constants/ContentFile.json';
import React, { MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { selectUserInfo, UserState } from '../SplashScreen/SplashSlice';
interface ISettingviewProps {
  onEmailToggleChange: (checked: boolean) => void;
  isEmailOn: boolean;
  onSmsToggleChange: (checked: boolean) => void;
  isSmsOn: boolean;
  inputValue?: UserState;
  showAlertToast: boolean;
  alertToastMessage: string;
  onModalClose: () => void;
  isModalOpen: boolean;
  cancelToast: () => void;
  inputAddedValue?: {
    phoneNumber?: string | number;
    phoneNumberError?: string;
    timezone?: string;
    timezoneError?: string;
  };
  onSaveData?: MouseEventHandler<HTMLButtonElement>;
  onInputChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  >;
  onClickClearSelect?: () => void;
  clearselectedValue?: boolean;
}
const SettingsView = (props: ISettingviewProps) => {
  const userDetails = useSelector((state: RootState) =>
    selectUserInfo(state)
  ).userDetails;
  const staticContents = constants.customerSettings;
  const moment = require('moment-timezone');
  const timeZones = moment.tz.names();

  // Convert timeZones into options format
  const timeZoneOptions = timeZones.map((tz: any) => ({
    value: tz,
    label: tz,
  }));
  return (
    <div className="relative">
      <div>
        <h3 className="text-2xl text-neutral-950 font-semibold py-4">
          {staticContents.settings}
        </h3>
      </div>
      <Card>
        <div className="grid md:grid-cols-12">
          <div className="md:col-span-10 lg:col-span-7 ">
            <span className="text-md font-semibold text-neutral-950">
              {staticContents.account}
            </span>
            <div className="text-neutral-500">
              {staticContents.accountDescription}
            </div>
            <div className="pt-4 flex flex-col gap-1">
              <Input
                label={staticContents.username}
                placeholder={staticContents.username}
                value={userDetails.name ? userDetails.name : ''}
                name={'username'}
                format={'capitialized'}
                variant={'disable'}
              />
              <Input
                label={staticContents.email}
                placeholder={staticContents.emailPlaceholder}
                value={userDetails.email ? userDetails.email : ''}
                name={'email'}
                variant={'disable'}
              />

              <div className="grid md:grid-cols-2 gap-4 pb-4">
                <Input
                  label={staticContents.phoneNumber}
                  placeholder={staticContents.phoneNumber}
                  value={props?.inputAddedValue?.phoneNumber}
                  name={'phoneNumber'}
                  onchange={props.onInputChange}
                  errors={
                    props.inputAddedValue?.phoneNumberError ? true : false
                  }
                  errorMessage={props.inputAddedValue?.phoneNumberError}
                  variant={
                    props.inputAddedValue?.phoneNumberError
                      ? 'danger'
                      : 'primary'
                  }
                />

                <DropDown
                  label={staticContents.timezone}
                  placeholder={staticContents.timezone}
                  value={props?.inputAddedValue?.timezone ? props?.inputAddedValue?.timezone :"US/Pacific"}
                  name={'timezone'}
                  onChange={props.onInputChange}
                  options={timeZoneOptions}
                  errors={props.inputAddedValue?.timezoneError ? true : false}
                  errorMessage={props.inputAddedValue?.timezoneError}
                  variant={
                    props.inputAddedValue?.timezoneError ? 'danger' : 'primary'
                  }
                  // clearselectedValue={props.clearselectedValue}
                  // onclickRemoveValue={props.onClickClearSelect}
                />
              </div>
              <div>
                <Button onClick={props.onSaveData}>Save</Button>
              </div>
            </div>

            <div className="container mx-auto">
              <div className="divider"></div>
            </div>

            <span className=" text-md font-semibold text-neutral-950">
              {staticContents.notifications}
            </span>
            <div className="text-neutral-500">
              {staticContents.notificationDescription}
            </div>
            <div className="py-4 flex gap-4 text-neutral-950 font-medium">
              <ToggleSwitch
                isOn={props.isEmailOn}
                onChange={props.onEmailToggleChange}
              />
              {staticContents.emailNotification}
            </div>
            {/* <div className="flex gap-4 text-neutral-950 font-medium">
              <ToggleSwitch
                isOn={props.isSmsOn}
                onChange={props.onSmsToggleChange}
              />
              {staticContents.smsNotification}
            </div> */}
          </div>
        </div>
      </Card>
      {/* alert success toast */}
      <Toast
        showToast={props.showAlertToast}
        Message={props.alertToastMessage}
        cancelToast={props.cancelToast}
        type={'success'}
      />
    </div>
  );
};

export default SettingsView;
