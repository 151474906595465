type RiskLevel = 'high_risk' | 'medium_risk' | 'resolved_risk';

interface RiskDescriptions {
  [key: string]: {
    [key in RiskLevel]: string;
  };
}

export const riskDescriptions: RiskDescriptions = {
  Payload: {
    high_risk: 'operating with excessive payload.',
    medium_risk: 'operating with elevated payload',
    resolved_risk: 'operating with elevated payload.',
  },
  Speed: {
    high_risk: 'operating with excessive speed.',
    medium_risk: 'operating beyond the speed limit.',
    resolved_risk: 'operating beyond the speed limit.',
  },
  Location: {
    high_risk: 'frequently exceeding the proximity range.',
    medium_risk: 'occasionally  exceeding the proximity range.',
    resolved_risk: 'showing minimal deviations from the proximity range.',
  },
  Safety: {
    high_risk: 'experiencing critical issues with the safety sensor.',
    medium_risk: 'experiencing signs of potential issues with safety sensors.',
    resolved_risk: 'experiencing critical issues with the safety sensor.',
  },
  Camera: {
    high_risk: 'experiencing severe malfunctions with camera sensor.',
    medium_risk: 'encountering moderate issues with camera sensors',
    resolved_risk: 'experiencing severe malfunctions with camera sensor.',
  },
  Software: {
    high_risk: 'running outdated software versions.',
    medium_risk: 'running slightly outdated software versions.',
    resolved_risk: 'running  slightly behind on software updates.',
  },
  Authorization: {
    high_risk: 'operating without an authorization mechanism.',
    medium_risk: 'experienced multiple unsuccessful login attempts.',
    resolved_risk: 'operating without an authorization mechanism.',
  },
  Configuration: {
    high_risk: 'operating without a proper configuration.',
    medium_risk: ' operating with an outdated configuration.',
    resolved_risk: 'operating with an outdated configuration.',
  },
  Calibration: {
    high_risk: 'operating without a defined speed limit.',
    medium_risk: 'operating without calibrated speed.',
    resolved_risk: 'operating without calibrated speed.',
  },
};


export const resolvedRiskDescriptions: RiskDescriptions = {
  Payload: {
    high_risk: 'operating with excessive payload.',
    medium_risk: 'operating with elevated payload.',
    resolved_risk: 'operated with minor speed variations.',
  },
  Speed: {
    high_risk: 'operating with excessive speed.',
    medium_risk: 'operating with elevated speed.',
    resolved_risk: ' operated with minor speed variations.',
  },
  Location: {
    high_risk: 'frequently exceeding the proximity range.',
    medium_risk: 'occasionally exceeding the proximity range.',
    resolved_risk: 'showed minimal deviations from the proximity range.',
  },
  Safety: {
    high_risk: 'experiencing critical issues with safety sensors.',
    medium_risk: 'showing signs of potential issues with safety sensors.',
    resolved_risk: 'had minor irregularities in safety sensor performance',
  },
  Camera: {
    high_risk: 'experiencing severe malfunctions with camera sensors.',
    medium_risk: 'encountering moderate issues with camera sensors.',
    resolved_risk: ' had minor issues with camera sensors.',
  },
  Software: {
    high_risk: 'running outdated software versions.',
    medium_risk: 'running slightly outdated software versions.',
    resolved_risk: 'were running slightly behind on software updates.',
  },
  Authorization: {
    high_risk: 'operating without authorization mechanisms.',
    medium_risk: 'experiencing multiple unsuccessful login attempts.',
    resolved_risk: 'operating without an authorization mechanism.',
  },
  Configuration: {
    high_risk: 'operating without proper configurations.',
    medium_risk: ' operating with outdated configurations.',
    resolved_risk: 'operating with an outdated configuration.',
  },
  Calibration: {
    high_risk: 'operating without defined speed limit.',
    medium_risk: 'operating without calibrated speed.',
    resolved_risk: 'operating without calibrated speed.',
  },
};
