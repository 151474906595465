import { useDispatch, useSelector } from 'react-redux';
import SettingsView from './CustomerSettings.view';

import { useEffect, useState } from 'react';
import constants from '../../constants/ContentFile.json';
import { AppDispatch, RootState } from '../../redux/store';
import { isValidValue, Loader } from '@kamet/common';
import ErrorPage from '../../common/component/ErrorPage/Errorpage';
import {
  notificationChange,
  selectUserInfo,
  selectUserInfoError,
  selectUserInfoLoading,
} from '../SplashScreen/SplashSlice';
import { isEmpty } from '../PersonalInfo/PersonalInfo.utils';
import { isValidData, isValidForm } from './CustomerSettings.utils';
import {
  setPhoneNumber,
  setTimezone,
  setUserData,
} from './CustomerSettings.slice';

const CustomerSettingspage = () => {
  const userDetails = useSelector((state: RootState) => selectUserInfo(state));
  const customerSettingslInfo = useSelector(
    (state: RootState) => state.customerInfo
  );

  const [isEmailOn, setIsEmailOn] = useState(
    userDetails.userDetails.isEmailNotification
  );
  const [isSmsOn, setIsSmsOn] = useState(
    userDetails.userDetails.isSmsNotification
  );
  const [showAlertToast, setShowAlertToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(false);

  const dispatch = useDispatch() as AppDispatch;
  const staticContents = constants.customerSettings;

  useEffect(() => {
    dispatch(setPhoneNumber(userDetails.userDetails.phoneNumber || ''));
    dispatch(setTimezone(userDetails.userDetails.timezone || ''));
  }, [userDetails]);

  useEffect(() => {
    if (showAlertToast) {
      setTimeout(() => {
        setShowAlertToast(false);
      }, 3000); // hide toast after 30sec
    }
  }, [showAlertToast]);

  const timeZoneValue = useSelector(
    (store: RootState) => store.userDetails.userDetails.timezone
  );

  useEffect(() => {
    if (timeZoneValue) {
      setSelectedValue(true);
    }
  }, []);

  // changing toggle button for email notification
  const onChangeEmailToggle = () => {
    setIsEmailOn(!isEmailOn);
    const newToggleState = !isEmailOn;
    const data = { preference: { email_notification: newToggleState } };
    dispatch(notificationChange(data));
    if (newToggleState) {
      setShowAlertToast(true);
      setToastMessage(staticContents.emailEnabled);
    } else {
      setShowAlertToast(true);
      setToastMessage(staticContents.emailDisabled);
    }
  };

  // changing toggle button for sms notification
  const onChangeSmsToggle = () => {
    setIsSmsOn(!isSmsOn);
    const newToggleState = !isSmsOn;
    const data = { preference: { sms_nofification: newToggleState } };
    dispatch(notificationChange(data));
    if (newToggleState) {
      setShowAlertToast(true);
      setToastMessage(staticContents.SMSEnabled);
    } else {
      setShowAlertToast(true);
      setToastMessage(staticContents.SMSDisabled);
    }
  };

  // on remove modal close
  const onModalClose = () => {
    setShowModal(false);
  };

  // on click cancel toast
  const onClickCancelToast = () => {
    setShowAlertToast(false);
  };
  const Loading = useSelector((state: RootState) =>
    selectUserInfoLoading(state)
  );
  const error = useSelector((state: RootState) => selectUserInfoError(state));
  // error page action button
  const ErrorActionClick = () => {
    window.location.reload();
  };
  if (Loading === 'pending') return <Loader />;
  if (error) {
    return (
      <ErrorPage
        statusCode={error.statusCode ? `${error.statusCode}` : ''}
        description={error.message}
        buttonText="Navigate to Dashboard"
        isButtonVisible={true}
        action={ErrorActionClick}
      />
    );
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    isValidData(name, value, dispatch);
    switch (name) {
      case 'phoneNumber':
        dispatch(setPhoneNumber(value));
        break;
      case 'timezone':
        dispatch(setTimezone(value));
        setSelectedValue(true);
        break;
      default:
        break;
    }
  };
  const saveButtonSubmit = () => {
    const isValid = isValidForm(customerSettingslInfo, dispatch);
    if (isValid) {
      // SUCCESS:TODO
      let data = {
        time_zone: customerSettingslInfo.timezone,
        phone_number: customerSettingslInfo.phoneNumber,
      };
      dispatch(setUserData(data));
      if (customerSettingslInfo.success === true) {
        setShowAlertToast(true);
        setToastMessage(staticContents.changeSuccess);
      }
    } else {
      //Todo
    }
  };

  const onClickClearSelect = () => {
    dispatch(setTimezone(''));
    setSelectedValue(false);
  };

  return (
    <div>
      <SettingsView
        inputValue={{ ...userDetails, loading: Loading }}
        isEmailOn={isEmailOn}
        onEmailToggleChange={onChangeEmailToggle}
        isSmsOn={isSmsOn}
        onSmsToggleChange={onChangeSmsToggle}
        showAlertToast={showAlertToast}
        alertToastMessage={toastMessage}
        onModalClose={onModalClose}
        isModalOpen={showModal}
        cancelToast={onClickCancelToast}
        inputAddedValue={customerSettingslInfo}
        onInputChange={handleInputChange}
        onSaveData={saveButtonSubmit}
        onClickClearSelect={onClickClearSelect}
        clearselectedValue={selectedValue}
      />
    </div>
  );
};

export default CustomerSettingspage;
