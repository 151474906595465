import { useEffect, useState } from 'react';
import SettingsDetails from './Settings.view';
import { isValidCostValue, isValidName } from './Settings.utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  fetchOrganizationData,
  reset,
  selectError,
  selectLoading,
  setCostPerminute,
  setOrganizationData,
  setProfileImage,
  setprofileImageErrorMsg,
  setTeamName,
} from './Settings.slice';
import { isValidImage } from '../../../common/utils/imageUpload.utils';
import Content from '../../../constants/ContentFile.json';
import { Loader } from '@kamet/common';
import ErrorPage from '../../../common/component/ErrorPage/Errorpage';
const staticContent = Content.organization.settings;

const Settings = () => {
  const [showToast, setShowToast] = useState(false); // state to handle toast
  const [toastMessage, setToastMessage] = useState(''); // state to handle toast message
  // assign states from reducer to a variable
  const settingsData = useSelector(
    (state: RootState) => state.organizationSettings
  );
  const error = useSelector((state: RootState) => selectError(state));
  const loading = useSelector((state: RootState) => selectLoading(state));
  const firstLetter = settingsData?.teamName
    ? settingsData?.teamName.charAt(0).toUpperCase()
    : '!';
  const dispatch = useDispatch() as AppDispatch; // assigning useDispatch function to a variable
  useEffect(() => {
    dispatch(fetchOrganizationData());
  }, [dispatch]);

  const [selectedImage, setSelectedImage] = useState<any>();
  // team name onchange function
  const onChangeTeamName = (e: any) => {
    const teamName = e.target.value;
    isValidName(teamName, dispatch);
    dispatch(setTeamName(teamName));
    setShowToast(false);
  };

  // team name onchange function
  const onChangeCost = (e: any) => {
    let cost = e.target.value;
    if (cost.includes('.')) {
      const [wholePart, decimalPart] = cost.split('.');
      // If there are more than 2 decimal places, trim it
      if (decimalPart?.length > 2) {
        cost = `${wholePart}.${decimalPart.slice(0, 2)}`;
      }
    }
    isValidCostValue(cost, dispatch);
    dispatch(setCostPerminute(cost));
    setShowToast(false);
  };

  // image upload function
  const onImageUpload = (e: any) => {
    setSelectedImage(e.target.files[0]);
    const { imagePath, isValid } = isValidImage(e);
    if (isValid) {
      dispatch(setProfileImage(imagePath));
      dispatch(setprofileImageErrorMsg(''));
    } else {
      dispatch(setprofileImageErrorMsg(staticContent.imageErrorMsg));
    }
  };
  // function to remove image - on remove button click
  const onClickRemoveImage = () => {
    dispatch(setProfileImage(''));
    dispatch(setprofileImageErrorMsg(''));
    setSelectedImage('');
  };
  // onclick save button
  const onClickSaveButton = () => {
    const teamName = settingsData.teamName;
    const isValidForm = isValidName(teamName, dispatch);
    if (isValidForm) {
      setShowToast(true);
      setToastMessage(staticContent.deleteToastMsg);
      const formData = new FormData();
      formData.append('business_name', settingsData.teamName);
      formData.append('image', selectedImage ? selectedImage : '');
      formData.append('cost', settingsData.costPerMinute);
      dispatch(setOrganizationData(settingsData.id, formData));
      dispatch(reset());
    }
  };
  const onClickCancelToast = () => {
    setShowToast(false);
  };
  // error page action button
  const ErrorActionClick = () => {
    window.location.reload();
  };

  if (loading === 'pending') return <Loader />;

  if (error)
    return (
      <ErrorPage
        statusCode={error.statusCode ? `${error.statusCode}` : ''}
        description={error.message}
        buttonText="Navigate to Dashboard"
        isButtonVisible={true}
        action={ErrorActionClick}
      />
    );

  const onKeyDown = (e: any) => {
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  };
  return (
    <>
      <SettingsDetails
        onUpload={onImageUpload}
        inputValue={settingsData.teamName}
        teamNamefirstLetter={firstLetter}
        errorMessage={settingsData.teamNameErrorMsg}
        imageValue={settingsData.profileUrl}
        profileImage={settingsData.profileUrl}
        onClick={onClickRemoveImage}
        onChangeName={(e) => onChangeTeamName(e)}
        onChangeCost={(e) => onChangeCost(e)}
        onClickSave={onClickSaveButton}
        showToast={showToast}
        toastMessage={toastMessage}
        cancelToast={onClickCancelToast}
        imageErrorMsg={settingsData.profileImageErrorMsg}
        costValue={settingsData.costPerMinute}
        costErrorMessage={settingsData.costErrorMessage}
        onKeyDown={onKeyDown}
      />
    </>
  );
};

export default Settings;
